@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Carme&display=swap');
@font-face {
  font-family: 'roxborough-cf-bold';
  src: url('./assests/fonts/roxborough-cf-bold/FontsFree-Net-roxborough-cf-bold.ttf');
  src: url('./assests/fonts/roxborough-cf-bold/676467e352c08c42ec346bba973cc2fd.eot');
  src: url('./assests/fonts/roxborough-cf-bold/676467e352c08c42ec346bba973cc2fd.woff')
      format('woff'),
    url('./assests/fonts/roxborough-cf-bold/676467e352c08c42ec346bba973cc2fd.woff2')
      format('woff2'),
    url('./assests/fonts/roxborough-cf-bold/676467e352c08c42ec346bba973cc2fd.svg')
      format('svg');
}
:root {
  --light: #0c97f9;
  --dark: #222270;
  --very-light: rgba(12, 151, 249, 0.1);
  --white-opacity: rgba(255, 255, 255, 0.5);
  --main-font: 'Carme', sans-serif;
  --title-font: 'roxborough-cf-bold';
  /* --main-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
} /*# sourceMappingURL=index.css.map */

body {
  font-family: var(--main-font);
}
.btn {
  padding: 0.7em 1em;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}
.swiperPagination {
  margin-top: 55px !important;
  margin-bottom: 0 !important;
}
